import { useMemo, useRef } from 'react';
import styles from './index.module.scss';
import LayoutEditor from 'src/components/common/layoutEditor';
import { useEffect, useState } from 'react';
import useTool from 'src/hook/useTool';
import useItem from 'src/hook/useItem';
import NavBarEditor from './NavBarEditor';
import { Button, Modal } from 'antd';
import { FEATURE, HEIGHT_STAGE, OUTLINE_PADDING_DEFAULT, WIDTH_STAGE } from 'src/assets/dataUI';
import AddText from './AddText';
import AddPanel from './AddPanel';
import AddStamp from './AddStamp';
import AddImage from './AddImage';
import AddBackground from './AddBackground';
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import SvgMerge from './SvgMerge';
import { convertStageDataToShapeData } from './utils';
import AddHole from './AddHole';
import AddSocket from './AddSocket';
import SettingBar from './SettingBar';
import { useNavigate, useParams } from 'react-router-dom';
import Ruler from 'src/components/common/ruler';
import { useRatioContext } from 'src/hook/useChangeRatio';

export default function EditorComponent({
  setImageUrl,
  setImageFile,
  background,
  setBackground,
  isAddToCart,
  detailData,
  infoGeneralTemplate,
  setIsAddItem,
  setIsSelectItem,
}: {
  setImageUrl: any;
  setImageFile: any;
  background: any;
  setBackground: any;
  isAddToCart: boolean;
  detailData: any;
  infoGeneralTemplate: any;
  setIsAddItem: (val: any) => void;
  setIsSelectItem: (val: any) => void;
}) {
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();

  const currentCoord = useRef<any>({
    x: 0,
    y: 0,
  });
  const selectedElement = useRef<any>(null);
  const currentEdittingIndex = useRef<any>(-1);
  const svgShapeEditing = useRef<any>(false);

  const startPositionDrag = useRef<any>({
    x: 0,
    y: 0,
  });

  const lastPositionDrag = useRef<any>({
    x: 0,
    y: 0,
  });
  const transformEditing = useRef<any>(0);
  const offsetEditing = useRef<any>({
    x: 0,
    y: 0,
  });

  const currentCenter = useRef<any>({
    x: 0,
    y: 0,
  });

  const startMouseRotate = useRef<any>({
    x: 0,
    y: 0,
  });

  const isRotate = useRef<any>(false);
  const isMoving = useRef<any>(false);

  const [feature, setFeature] = useState<string>();
  const [editStep, setEditStep] = useState<3 | 4 | undefined>();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isOpenModalBack, setIsOpenModalBack] = useState<boolean>(false);
  const [editingIndex, setEdittingIndex] = useState<any>(-1);

  const {stateRatio, setStateRatio} = useRatioContext()

  useEffect(()=>{
    const [numerator, denominator] =infoGeneralTemplate?.aspect_ratio.split('/').map(Number);

    const max_width = 290;
    const max_height = max_width * (numerator/denominator);


    let height = max_height;
    let width = height / (numerator/denominator);

    if (width > max_width) {
      width = max_width;
      height = width * (numerator/denominator);
    }

    if (height > max_height) {
      height = max_height;
      width = height / (numerator/denominator);
    }

    setStateRatio((prevState: any) => ({ ...prevState, 
      ratioWidth: width,
      ratioHeight: height,
    }));
  }, [infoGeneralTemplate, setStateRatio]);

  useEffect(() => {
    setIsSelectItem(editingIndex > -1);
  }, [editingIndex]);

  let { stageData, removeItem, updateItem, initItem } = useItem();

  const sortedStageData = useMemo(() => {
    const type99Or100 = stageData?.filter((item) =>
      item?.attrs?.shapeData.some((shape: any) => shape?.type === 99 || shape?.type === 100)
    );

    const otherItems = stageData
      ?.filter((item) => !item?.attrs?.shapeData?.some((shape: any) => shape?.type === 99 || shape?.type === 100))
      .sort((a, b) => a?.attrs?.zIndex - b?.attrs?.zIndex);

    return [...otherItems, ...type99Or100];
  }, [stageData]);
  // console.log({ sortedStageData });

  // edit
  const handleEdit = (step: 3 | 4 | undefined) => {
    setEditStep(step);
    setIsShowModal(false);
    setIsAddItem(true);
  };

  const { getClickCallback } = useTool(sortedStageData[editingIndex], setIsShowModal, setEdittingIndex, handleEdit);

  const navBar = <NavBarEditor getClickCallback={getClickCallback} ratioSize = {stateRatio} />;
  const settingBar = <SettingBar setFeature={setFeature} setIsAddItem={setIsAddItem} />;

  //add to cart
  useEffect(() => {
    if (isAddToCart) {
      const svg = document.getElementById('Layer_1');
      if (svg) {
        const svgString = new XMLSerializer().serializeToString(svg);
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
        const svgUrl = URL.createObjectURL(svgBlob);

        setImageUrl?.(svgUrl);
        setImageFile?.(svgBlob);
      }

      if (id) {
        history(`/product/templates/edit/${id}/confirm`);
      } else {
        history('/product/templates/create/confirm');
      }
    }
  }, [isAddToCart]);

  // feature
  const handleSetFeature = () => {
    setEditStep(undefined);
    setFeature(undefined);
    setIsAddItem(false);
  };

  const renderFeature = () => {
    switch (feature) {
      case FEATURE.text:
        return <AddText handleSetFeature={handleSetFeature} editMode={false} baseSvgContent={false} defaultStep={0} />;
      case FEATURE.icon:
        return (
          <AddStamp
            handleSetFeature={handleSetFeature}
            feature={FEATURE.icon}
            editMode={false}
            baseSvgContent={false}
            defaultStep={0}
          />
        );
      case FEATURE.panel:
        return <AddPanel handleSetFeature={handleSetFeature} editMode={false} baseSvgContent={false} defaultStep={0} />;
      case FEATURE.image:
        return <AddImage handleSetFeature={handleSetFeature} editMode={false} baseSvgContent={false} defaultStep={0} />;
      case FEATURE.background:
        return <AddBackground handleSetFeature={handleSetFeature} setBackgroundImage={setBackground} />;
      case FEATURE.hole:
        return <AddHole handleSetFeature={handleSetFeature} infoGeneralTemplate={infoGeneralTemplate} />;
      default:
        return <AddSocket handleSetFeature={handleSetFeature} infoGeneralTemplate={infoGeneralTemplate} />;
    }
  };

  const onDragEnd = (editingIndexData: any) => (e: any) => {
    if (e.index > -1 && e.x != 0 && e.y != 0) {
      let shapeData = sortedStageData[e.index]?.attrs?.shapeData?.map((stageInfo: any, indexData: any) => {
        if (stageInfo?.transform) {
          return {
            ...stageInfo,
            transform: {
              x: stageInfo?.transform?.x + e.x,
              y: stageInfo?.transform?.y + e.y,
            },
          };
        } else {
          return {
            ...stageInfo,
            transform: {
              x: e.x,
              y: e.y,
            },
          };
        }
      });
      let newObject = {
        attrs: {
          shapeData,
        },
      };

      updateItem(sortedStageData[e.index]?.id, (prevData: any) => ({
        ...newObject?.attrs,
      }));
    }
  };

  const onRotateEnd = (editingIndexData: any) => (e: any) => {
    if (e.index > -1 && e.a != 0) {
      let shapeData = sortedStageData[e.index]?.attrs.shapeData?.map((stageInfo: any, indexData: any) => {
        return {
          ...stageInfo,
          rotate: {
            a: e.a,
            x: e.x,
            y: e.y,
            baseScale: e.baseScale,
          },
        };
      });
      let newObject = {
        attrs: {
          shapeData,
        },
      };

      updateItem(sortedStageData[e.index]?.id, (prevData: any) => ({
        ...newObject?.attrs,
      }));
    }
  };

  useEffect(() => {
    if (detailData) {
      const editor_content = JSON.parse(detailData?.editor_content);
      initItem(editor_content);
      setBackground(detailData?.background);
    }
  }, [detailData]);

  if (editingIndex > -1 && editStep) {
    //we do not need new component here, just add edit mode to old component and everything will be fine
    const data = sortedStageData[editingIndex];

    switch (data?.attrs?.shapeData[0]?.type) {
      case 9: //image info
        //check if data have original or not
        return (
          <AddImage
            defaultStep={editStep === 3 ? 2 : 3}
            editMode={true}
            baseSvgContent={data}
            handleSetFeature={handleSetFeature}
          />
        );
      case 1: //text info
        return <AddText defaultStep={3} editMode={true} baseSvgContent={data} handleSetFeature={handleSetFeature} />;
      case 8: //panel info
        return <AddPanel defaultStep={2} editMode={true} baseSvgContent={data} handleSetFeature={handleSetFeature} />;
    }
  }

  return (
    <>
      {feature ? (
        renderFeature()
      ) : (
        <>
          <Ruler
            startValue={0}
            endValue={infoGeneralTemplate?.width || 0}
            step={(infoGeneralTemplate?.width || 0) / 10}
            width={stateRatio?.ratioWidth}
          />

          <div className={styles.wrapRulerAndEditor}>
            <div
              className={styles.rulerVertical}
              style={{
                width: stateRatio?.ratioHeight,
                height: stateRatio?.ratioWidth,
                top: (stateRatio?.ratioHeight - stateRatio?.ratioWidth) / 2,
                // (window.innerWidth - HEIGHT_STAGE) / 2 is position of editor
                // 28 is height of ruler
                // 4 is distance between ruler and editor
                left: `calc((100% - ${stateRatio?.ratioHeight}px) / 2 - 28px - 4px)`,
              }}
            >
              <Ruler
                startValue={0}
                endValue={infoGeneralTemplate?.height || 0}
                step={(infoGeneralTemplate?.height || 0) / 10}
                width={stateRatio?.ratioHeight}
                reverse
              />
            </div>

            <div className={styles.editor} id="editor">
              <LayoutEditor
                navBar={navBar}
                settingBar={settingBar}
                isSelect={
                  editingIndex > -1 &&
                  ![99, 100]?.includes(
                    sortedStageData[editingIndex]?.attrs?.shapeData
                      ? sortedStageData[editingIndex]?.attrs?.shapeData[0]?.type
                      : -1
                  ) &&
                  sortedStageData[editingIndex]?.attrs?.shapeData &&
                  sortedStageData[editingIndex]?.attrs?.shapeData[0]?.cate !== 'socket'
                }
              >
                <div className={styles.wrapLayoutEditor}>
                  <div
                    className={styles.layoutEditor}
                    style={{
                      width: stateRatio?.ratioWidth,
                      height: stateRatio?.ratioHeight,
                    }}
                  >
                    <SvgMerge
                      backgroundImage={background ? `url("${background}")` : 'url("")'}
                      shapeData={convertStageDataToShapeData(sortedStageData)}
                      outline={{
                        showOutline: true,
                        outlinePadding: OUTLINE_PADDING_DEFAULT,
                        outlineWidth: 1,
                        outlineColor: 'black',
                        paddingColor: 'white',
                      }}
                      handleClick={(indexInArray: any) => {
                        setEdittingIndex(indexInArray ?? -1);
                      }}
                      hiddenIndex={editingIndex}
                      onDragEnd={onDragEnd(editingIndex)}
                      onRotateEnd={onRotateEnd(editingIndex)}
                      allowClickSvG={true}
                      removeItem={removeItem}
                      extraState={{
                        isRotate: isRotate.current,
                        svgShapeEditing: svgShapeEditing.current,
                        startPositionDrag: startPositionDrag.current,
                        lastPositionDrag: lastPositionDrag.current,
                        transformEditing: transformEditing.current,
                        offsetEditing: offsetEditing.current,
                        currentCenter: currentCenter.current,
                        startMouseRotate: startMouseRotate.current,
                        isMoving: isMoving.current,
                        currentEdittingIndex: currentEdittingIndex.current,
                        selectedElementData: selectedElement.current,
                        currentCoordData: currentCoord,
                      }}
                    />
                  </div>
                </div>
              </LayoutEditor>
            </div>
          </div>
        </>
      )}

      <ModalEdit isShowModal={isShowModal} setIsShowModal={setIsShowModal} handleEdit={handleEdit} />

      <ModalConfirm
        isShowModal={isOpenModalBack}
        onCancel={() => setIsOpenModalBack(false)}
        onOk={() => history('/acrylics')}
        content="中止確認"
        subContent="作成を中止して、前のTOP画面に戻りますか？"
        textOk="戻る"
      />
    </>
  );
}

const ModalEdit = ({
  isShowModal,
  setIsShowModal,
  handleEdit,
}: {
  isShowModal: boolean;
  setIsShowModal: (val: boolean) => void;
  handleEdit: (val: 3 | 4 | undefined) => void;
}) => {
  return (
    <Modal
      title=""
      open={isShowModal}
      width={350}
      className={styles.modalConfirm}
      footer={false}
      centered={true}
      onCancel={() => setIsShowModal(false)}
    >
      <p className={styles.content}>編集確認</p>
      <div className={styles.footerForm}>
        <Button className={styles.buttonForm} onClick={() => handleEdit(3)}>
          画像調整
        </Button>

        <Button className={styles.buttonForm} onClick={() => handleEdit(4)}>
          サイズ調整
        </Button>
      </div>
    </Modal>
  );
};
