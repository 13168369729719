import { useFetchParts } from 'src/api/useFetchParts';
import styles from '../index.module.scss';
import { HEIGHT_STAGE, WIDTH_PANEL_STAMP_ITEM, WIDTH_STAGE } from 'src/assets/dataUI';
import { Button } from 'antd';
import { cropVisibleArea } from 'src/utils';
import { nanoid } from 'nanoid';
import { useRatioContext } from 'src/hook/useChangeRatio';

type Props = {
  stamp: any;
  setStamp: (val: any) => void;
  setStampOrigin: (val: any) => void;
  setStep: (val: any) => void;
  setImageBackUp: (val: any) => void;
  svgContent: any;
  setNewImage: any;
  newImage: any;
  imageSize: any;
  setImageSize: any;
  handleSetFeature: any;
  imageObject: any;
  setImageObject: any;
  currentSelectStamp: any;
  setCurrentSelectStamp: any;
  setInitImage: any;
};

export default function Step1({
  stamp,
  setStamp,
  setStampOrigin,
  setStep,
  setImageBackUp,
  setNewImage,
  newImage,
  imageSize,
  setImageSize,
  handleSetFeature,
  imageObject,
  setImageObject,
  currentSelectStamp,
  setCurrentSelectStamp,
  setInitImage,
}: Props) {
  const { data } = useFetchParts([1]);
  const {stateRatio} = useRatioContext()

  const handleClickStamp = (imageItem: any) => {
    setCurrentSelectStamp(imageItem?.id);
    setImageObject(imageItem);
    // setStampOrigin({
    //   id: id,
    //   url: urlOrigin,
    // });
    // const image = new Image();
    // image.crossOrigin = "Anonymous";
    // image.onload = () => {
    //   const canvas = document.createElement("canvas");
    //   canvas.width = image.width;
    //   canvas.height = image.height;
    //   const ctx: any = canvas.getContext("2d");
    //   ctx.drawImage(image, 0, 0);

    //   const imageBase64Data = canvas.toDataURL("image/png");

    //   cropVisibleArea(
    //     imageBase64Data,
    //     (
    //       croppedBase64Data: any,
    //       width: any,
    //       height: any,
    //       minX: any,
    //       minY: any,
    //       baseWidth: any,
    //       baseHeight: any
    //     ) => {
    //       setNewImage(croppedBase64Data);
    //       setImageSize({
    //         x: minX,
    //         y: minY,
    //         width: width,
    //         height: height,
    //         baseWidth: baseWidth,
    //         baseHeight: baseHeight,
    //       });
    //     }
    //   );
    //   setStamp({
    //     type: "image",
    //     id: id,
    //     name: "imported image",
    //     src: imageBase64Data,
    //   });
    //   setImageBackUp({
    //     type: "image",
    //     id: nanoid(),
    //     name: "imported image backup",
    //     src: imageBase64Data,
    //   });
    // };
    // image.src = url;
  };

  return (
    <div className={styles.step1}>
      <div className={styles.wrapChooseStamp}>
        <div
          className={styles.img}
          style={{
            width: stateRatio?.ratioWidth,
            height: stateRatio?.ratioHeight,
          }}
        >
          {imageObject?.file_path_url && (
            <img
              src={imageObject?.file_path_url}
              alt="stamp"
              style={{ width: WIDTH_PANEL_STAMP_ITEM, height: WIDTH_PANEL_STAMP_ITEM }}
            />
          )}
        </div>

        <div
          className={styles.listStamp}
          style={{
            height: HEIGHT_STAGE,
          }}
        >
          {data?.data[1]?.map((item: any) => {
            return (
              <div className={styles.stampType} key={item.title}>
                <p className={styles.title}>{item.name}</p>

                <div className={styles.stamps} id="stamps">
                  {item?.parts?.map((i: any) => {
                    return (
                      <div
                        className={`${styles.stampItem} ${i.id === currentSelectStamp ? styles.stampItemBorder : ''}`}
                        key={i.key}
                        onClick={() => handleClickStamp(i)}
                      >
                        <img src={i?.file_path_url} alt={i?.file_name} />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex justify-center gap-2">
        <Button
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            handleSetFeature();
          }}
        >
          {'<<詳細画面へ戻る'}
        </Button>
        <Button
          className="button-form button-form-Submit !w-auto"
          disabled={!currentSelectStamp}
          onClick={() => {
            setInitImage(true);
            setStep((prev: number) => prev + 1);
          }}
        >
          {'次のステップへ>>'}
        </Button>
      </div>
    </div>
  );
}
