import { useEffect, useState } from 'react';
import styles from 'src/components/features/superAdmin/EditorComponent/FinalStep/index.module.scss';
import stylesStamp from '../index.module.scss';
import {
  HEIGHT_STAGE,
  OUTLINE_PADDING_DEFAULT,
  OUTLINE_PADDING_TEXT_RANGE_SLIDER,
  RANGE_SLIDER_STEP_SIZE,
  SCALE_RANGE_SLIDER,
  SIZE_STAMP_RANGE_SLIDER,
  WIDTH_PANEL_STAMP_ITEM,
  WIDTH_STAGE,
} from 'src/assets/dataUI';
import { nanoid } from 'nanoid';
import { MenyStepChangeSize } from '../../SvgMerge/menu';
import {
  DEFAULT_EXTRA_STATE,
  calculateSvgSize,
  getPathMinxMiny,
  revertCalculationBeforeTransform,
  revertPathStringByTransform,
} from '../../SvgMerge/utils';
import useItem from 'src/hook/useItem';
import SvgMerge from '../../SvgMerge';
import MenuBottom from 'src/components/common/menuBottom';
import { Button, Slider } from 'antd';
import { cropVisibleArea } from 'src/utils';
import { useRatioContext } from 'src/hook/useChangeRatio';

const Step3AddStamp = ({
  svgContent,
  svgContentOrigin,
  width,
  setWidth,
  newImage,
  setNewImage,
  imageSize,
  setStep,
  isDeleteReCover,
  handleSetFeature,
}: any) => {
  const { createItem } = useItem();
  const [currentTab, setCurrentTab] = useState(0);
  const [outlinePadding, setOutlinePadding] = useState(OUTLINE_PADDING_DEFAULT);
  const [scaleRatio, setScaleRatio] = useState<any>(svgContent?.scale?.base ?? 1);
  const [shapeSize, setShapeSize] = useState<any>();
  const [scaleSlider, setScaleSlider] = useState<any>(SCALE_RANGE_SLIDER);
  const {stateRatio} = useRatioContext()

  useEffect(() => {
    let shapeSize = calculateSvgSize({
      idToCheck: 'svg_outline_shape',
      baseShapeInfo: svgContent,
      isText: false,
    });

    shapeSize = revertCalculationBeforeTransform({
      transform: {
        x: 0,
        y: 0,
      },
      scale: {
        base: scaleRatio,
      },
      rotate: {
        a: 0,
        x: shapeSize.width / 2,
        y: shapeSize.height / 2,
        baseScale: scaleRatio,
      },
      outputSvgSize: shapeSize,
    });

    setShapeSize(shapeSize);
  }, [svgContent, svgContentOrigin, scaleRatio]);

  const onSubmitFinal = () => {
    let shapeSize = calculateSvgSize({
      idToCheck: 'svg_outline_shape',
      baseShapeInfo: svgContent,
      isText: false,
    });

    shapeSize = revertCalculationBeforeTransform({
      transform: {
        x: 0,
        y: 0,
      },
      scale: {
        base: scaleRatio,
      },
      rotate: {
        a: 0,
        x: shapeSize.width / 2,
        y: shapeSize.height / 2,
        baseScale: scaleRatio,
      },
      outputSvgSize: shapeSize,
    });

    let moveObject = getPathMinxMiny({
      pathStringArray: svgContent?.children?.map((pathInfo: any) => {
        return pathInfo?.attributes?.d;
      }),
      transform: {
        x: shapeSize?.x - svgContent?.transform?.x,
        y: shapeSize?.y - svgContent?.transform?.y,
      },
    });

    //we need to add extra change if size of image change
    // moveObject = {
    //   x: moveObject.x - (svgContent?.size_image?.width - shapeSize.width)/2,
    //   y: moveObject.y - (svgContent?.size_image?.height - shapeSize.height)/2
    // }

    // console.log(shapeSize,moveObject,svgContent)

    let newPath = svgContent?.children?.map((pathInfo: any) => {
      return {
        ...pathInfo,
        attributes: {
          ...pathInfo?.attributes,
          d: revertPathStringByTransform({
            pathString: pathInfo?.attributes?.d,
            transform: {
              x: shapeSize?.x - svgContent?.transform?.x,
              y: shapeSize?.y - svgContent?.transform?.y,
            },
            moveObject,
          }),
        },
      };
    });

    cropVisibleArea(
      svgContent?.image_url,
      (
        imageBase64Data: any,
        widthData: any,
        heightData: any,
        minX: any,
        minY: any,
        baseWidth: any,
        baseHeight: any
      ) => {
        //will need to revert affected path here
        createItem({
          id: nanoid(),
          attrs: {
            zIndex: 1,
            shapeData: [
              {
                type: 9,
                ...svgContent,
                attributes: {
                  ...svgContent?.attributes,
                  width: shapeSize.width,
                  height: shapeSize.height,
                  viewBox: `0 0 ${shapeSize.width} ${shapeSize.height}`,
                },
                width: shapeSize.width,
                height: shapeSize.height,
                image_url: imageBase64Data,
                children: svgContent?.original ? newPath : newPath,
                outlinePadding: outlinePadding,
                size_image: undefined,
                transform: {
                  x: (stateRatio?.ratioWidth - svgContent.width * scaleRatio) / 2,
                  y: (stateRatio?.ratioHeight - svgContent.height * scaleRatio) / 2,
                },
                scale: {
                  base: scaleRatio,
                },
                scaleSlider,
                rotate: {
                  a: 0,
                  x: shapeSize.width / 2,
                  y: shapeSize.height / 2,
                  baseScale: scaleRatio,
                },
                shapeSize: {
                  x: 0,
                  y: 0,
                  width: shapeSize.width,
                  height: shapeSize.height,
                },
                lastShapeSizeData: {
                  width: widthData,
                  height: heightData,
                  minX,
                  minY,
                  baseWidth,
                  baseHeight,
                },
              },
            ],
          },
        });
      }
    );

    handleSetFeature();
  };

  return (
    <>
      <div className={styles.editor}>
        <div
          className={styles.stage}
          style={
            {
              width: stateRatio?.ratioWidth,
              height: stateRatio?.ratioHeight,
              '--bg-pos': `0px 0px`,
              '--bg-size': `0px 0px`,
            } as React.CSSProperties
          }
        >
          {svgContent && (
            <SvgMerge
              shapeData={[
                {
                  type: 9,
                  ...svgContent,
                  outlinePadding: outlinePadding,
                  transform: {
                    x: (stateRatio?.ratioWidth - svgContent.width * scaleRatio) / 2,
                    y: (stateRatio?.ratioHeight - svgContent.height * scaleRatio) / 2,
                  },
                  scale: {
                    base: scaleRatio,
                  },
                  rotate: {
                    a: 0,
                    x: 0,
                    y: 0,
                    baseScale: scaleRatio,
                  },
                  shapeSize: {
                    x: 0,
                    y: 0,
                    width: shapeSize?.width,
                    height: shapeSize?.height,
                  },
                },
              ]}
              outline={{
                showOutline: true,
              }}
            />
          )}
        </div>
      </div>

      <div style={{ display: 'none' }}>
        <SvgMerge
          shapeData={[
            {
              type: 9,
              ...svgContent,
              outlinePadding: OUTLINE_PADDING_DEFAULT,
              scale: {
                base: scaleRatio,
              },
            },
          ]}
          outline={{
            showOutline: false,
          }}
          extraState={DEFAULT_EXTRA_STATE}
          onDragDeleteEnd={(e: any) => console.log(e)}
          isSingleShowMode={true}
        />
      </div>
      <canvas id="canvas" width="800" height="400" style={{ display: 'none' }}></canvas>

      <div className="my-react-add-text flex justify-center mb-2">
        <div className="w-[500px]">
          <div className={stylesStamp.content}>
            {currentTab === 1 && (
              <>
                <p className={stylesStamp.scaleTitle}>拡大・縮小</p>

                <div className={stylesStamp.scale}>
                  <Slider
                    tooltip={{ formatter: null }}
                    defaultValue={scaleSlider}
                    min={SCALE_RANGE_SLIDER - SIZE_STAMP_RANGE_SLIDER}
                    max={SCALE_RANGE_SLIDER + SIZE_STAMP_RANGE_SLIDER}
                    step={RANGE_SLIDER_STEP_SIZE}
                    onChange={(e) => {
                      setScaleSlider(Number(e));
                      setScaleRatio(Number(SCALE_RANGE_SLIDER + Number(e) / 10));
                    }}
                  />
                </div>
              </>
            )}
            {currentTab === 2 && (
              <>
                <p className={stylesStamp.scaleTitle}>縁取りサイズ</p>

                <div className={stylesStamp.scale}>
                  <Slider
                    tooltip={{ formatter: null }}
                    defaultValue={outlinePadding}
                    min={OUTLINE_PADDING_DEFAULT - OUTLINE_PADDING_TEXT_RANGE_SLIDER}
                    max={OUTLINE_PADDING_DEFAULT + OUTLINE_PADDING_TEXT_RANGE_SLIDER}
                    step={RANGE_SLIDER_STEP_SIZE}
                    onChange={(e) => {
                      setOutlinePadding(Number(e));
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <MenuBottom
        list={MenyStepChangeSize({
          onClickChangeWidth: () => setCurrentTab(1),
          onClickEnlarge: () => setCurrentTab(2),
        })}
      />

      <div className="flex justify-center gap-2 mt-3">
        <Button
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            setStep((prev: number) => prev - 1);
          }}
        >
          {'<<前のステップへ'}
        </Button>
        <Button className="button-form button-form-Submit !w-auto" onClick={() => onSubmitFinal()}>
          OK
        </Button>
      </div>
    </>
  );
};

export default Step3AddStamp;
