import React from 'react';
import { generateTransformAttributes } from '../../utils';

// type 9
const SvgContentListImage = ({
  shapeInfo,
  outline,
  drawOutline,
  handleClick,
  index,
  isSingleShowMode,
  isRestoreMode,
  whiteBackground,
}: any) => {
  const widthViewBox = Number(shapeInfo?.attributes?.width);
  const heightViewBox = Number(shapeInfo?.attributes?.height);
  // console.log({ shapeInfo, widthViewBox, heightViewBox });

  const filterAttributes = (attributes: any) => {
    const filteredAttributes = { ...attributes };
    if (filteredAttributes.style) {
      delete filteredAttributes.style;
    }
    return filteredAttributes;
  };

  const renderChild = (child: any) => {
    if (child.children.length > 0) {
      return <>{child.children.map((nestedChild: any) => renderChild(nestedChild))}</>;
    } else {
      return (
        <child.tagName {...filterAttributes(child.attributes)} key={child.attributes.id}>
          {child.content && child.content !== '\n' && child.content}
        </child.tagName>
      );
    }
  };

  return drawOutline ? (
    <g
      id={`pathsImageId`}
      transform={generateTransformAttributes({
        shapeInfo,
        isApplyMatrix: true,
      })}
    >
      {shapeInfo?.children?.map((pathListItem: any) => {
        if (pathListItem.children.length > 0) {
          return (
            <pathListItem.tagName
              {...filterAttributes(pathListItem.attributes)}
              key={pathListItem.attributes.id}
              onClick={handleClick}
              fill={outline?.paddingColor ?? 'white'}
              fillRule={shapeInfo?.fillRule}
              clipRule={shapeInfo?.clipRule}
              stroke={shapeInfo?.stroke ?? 'black'}
              stroke-width={shapeInfo?.outlinePadding}
              strokeLinecap="round"
              strokeLinejoin={shapeInfo?.strokeLinejoin ?? 'round'}
              opacity="1"
            >
              {pathListItem.children.map((child: any) => renderChild(child))}
            </pathListItem.tagName>
          );
        } else {
          return (
            <pathListItem.tagName
              {...filterAttributes(pathListItem.attributes)}
              key={pathListItem.attributes.id}
              onClick={handleClick}
              fill={outline?.paddingColor ?? 'white'}
              fillRule={shapeInfo?.fillRule}
              clipRule={shapeInfo?.clipRule}
              stroke={shapeInfo?.stroke ?? 'black'}
              stroke-width={shapeInfo?.outlinePadding}
              strokeLinecap="round"
              strokeLinejoin={shapeInfo?.strokeLinejoin ?? 'round'}
              opacity="1"
            >
              {pathListItem.content && pathListItem.content !== '\n' && pathListItem.content}
            </pathListItem.tagName>
          );
        }
      })}
    </g>
  ) : shapeInfo?.image_url ? (
    <g mask={'url(#smallmask)'}>
      <g
        filter={whiteBackground ? `url(#negative)` : ''}
        mask={
          Array.isArray(shapeInfo?.deletePath) && shapeInfo?.deletePath?.length > 0
            ? `url(#mask_delete_${isSingleShowMode ? '_' + index : index})`
            : ''
        }
      >
        <image
          href={shapeInfo?.image_url}
          id={index}
          onClick={handleClick}
          // fill={outline?.paddingColor ?? 'white'}
          fillRule={shapeInfo?.fillRule}
          clipRule={shapeInfo?.clipRule}
          stroke={shapeInfo?.stroke ?? 'black'}
          strokeWidth={shapeInfo?.outlinePadding}
          strokeLinecap="round"
          strokeLinejoin={shapeInfo?.strokeLinejoin ?? 'round'}
          width={shapeInfo?.size_image?.width ?? widthViewBox}
          height={shapeInfo?.size_image?.height ?? heightViewBox}
          transform={generateTransformAttributes({
            shapeInfo,
            isApplyMatrix: true,
            isTransformImage: true,
          })}
        />
      </g>
      {isRestoreMode || isSingleShowMode ? (
        <g>
          {shapeInfo?.imageBackUp ? (
            <image
              filter={whiteBackground ? `url(#negative)` : ''}
              mask={`url(#mask_restore_${index})`}
              style={{ visibility: 'visible' }}
              href={shapeInfo?.imageBackUp?.src}
              id={'base_' + index}
              onClick={handleClick}
              // fill={outline?.paddingColor ?? 'white'}
              opacity={1}
              fillRule={shapeInfo?.fillRule}
              clipRule={shapeInfo?.clipRule}
              stroke={shapeInfo?.stroke ?? 'black'}
              strokeWidth={shapeInfo?.outlinePadding}
              strokeLinecap="round"
              strokeLinejoin={shapeInfo?.strokeLinejoin ?? 'round'}
              width={shapeInfo?.size_image?.width ?? widthViewBox}
              height={shapeInfo?.size_image?.height ?? heightViewBox}
              transform={generateTransformAttributes({
                shapeInfo,
                isApplyMatrix: true,
              })}
            />
          ) : (
            <></>
          )}
        </g>
      ) : (
        <></>
      )}
    </g>
  ) : (
    <></>
  );
};

export default SvgContentListImage;
