import { useEffect, useRef, useState } from 'react';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import { generateSvgFromUrl } from '../SvgMerge/ImageToSvg';
import Step3 from './components/Step3';
import { HEIGHT_STAGE, OUTLINE_PADDING_DEFAULT, WIDTH_STAGE } from 'src/assets/dataUI';
import { Button } from 'antd';
import { nodeToObject } from 'src/utils';
import styles from './index.module.scss';
import { generateBlobFromBase64, revertPathStringByScale } from '../SvgMerge/utils';
import { changeColInUri } from '../utils';
import { useRatioContext } from 'src/hook/useChangeRatio';

export default function AddImage({ handleSetFeature, editMode, baseSvgContent, defaultStep }: any) {
  const [step, setStep] = useState<number>(editMode ? defaultStep : 1);
  const imageRef = useRef(null);
  const [image, setImage] = useState<any>();
  const [newImage, setNewImage] = useState<any>();
  const {stateRatio} = useRatioContext()
  const [imageBackUp, setImageBackUp] = useState<any>(
    editMode && baseSvgContent ? { src: baseSvgContent?.attrs?.shapeData[0]?.image_url } : false
  );
  const [svgContent, setSvgContent] = useState<any>(
    editMode && baseSvgContent
      ? {
          ...baseSvgContent?.attrs?.shapeData[0],
          transform: {
            x:
              (stateRatio?.ratioWidth -
                baseSvgContent?.attrs?.shapeData[0]?.width * baseSvgContent?.attrs?.shapeData[0]?.scale?.base) /
              2,
            y:
              (stateRatio?.ratioHeight -
                baseSvgContent?.attrs?.shapeData[0]?.height * baseSvgContent?.attrs?.shapeData[0]?.scale?.base) /
              2,
          },
          matrix: false,
          rotate: {
            a: 0,
            x: baseSvgContent?.attrs?.shapeData[0]?.rotate?.x ?? 0,
            y: baseSvgContent?.attrs?.shapeData[0]?.rotate?.y ?? 0,
            baseScale: 1,
          },
        }
      : false
  );
  const [isRemoveBg, setIsRemoveBg] = useState(true);
  const [deletePath, setDeletePath] = useState([]);
  const [resetFromStep1, setResetFromStep1] = useState<any>(false);
  const [imageSize, setImageSize] = useState<any>({});

  const MAX_WIDTH_IMAGE = stateRatio?.ratioWidth - 50;
  const MAX_HEIGHT_IMAGE = stateRatio?.ratioHeight - 50;

  useEffect(() => {
    if (image?.src) {
      let blobIn = generateBlobFromBase64({
        base64String: image?.src,
      });

      var DOMURL = self.URL || self.webkitURL || self;
      var url: any = DOMURL.createObjectURL(blobIn);

      changeColInUri({
        base64: image.src,
        colto: '#000000',
        callBack: (newUrl: any) => {
          generateSvgFromUrl(newUrl, (svg: any) => {
            if (svg) {
              const parser = new DOMParser();
              const svgDoc = parser.parseFromString(svg, 'image/svg+xml');

              const img = new Image();
              img.onload = () => {
                let SCALE_BY_WIDTH = MAX_WIDTH_IMAGE / img.width;
                let SCALE_BY_HEIGHT = MAX_HEIGHT_IMAGE / img.height;

                let scaleRatio = 1;

                if (SCALE_BY_WIDTH > SCALE_BY_HEIGHT) {
                  scaleRatio = SCALE_BY_HEIGHT;
                } else {
                  scaleRatio = SCALE_BY_WIDTH;
                }

                if (svgDoc && scaleRatio) {
                  const svgContent = nodeToObject(svgDoc);
                  let filteredData = svgContent?.children[0]?.children;

                  if (isRemoveBg) {
                    filteredData = svgContent?.children[0]?.children.filter((item: any) => {
                      return item.attributes && item.attributes.d.slice(0, 5) !== 'M 0 0';
                    });
                  }

                  filteredData = filteredData.map((pathInfo: any) => {
                    return {
                      ...pathInfo,
                      attributes: {
                        ...pathInfo?.attributes,
                        d: revertPathStringByScale({
                          pathString: pathInfo?.attributes?.d,
                          scale: {
                            base: 1 / scaleRatio,
                          },
                        }),
                      },
                    };
                  });

                  setSvgContent({
                    ...svgContent?.children[0],
                    children: filteredData,
                    image_url: image?.src,
                    cate: 'image',
                    outlinePadding: OUTLINE_PADDING_DEFAULT,
                    scale: {
                      base: 1,
                    },
                    attributes: {
                      ...svgContent?.children[0]?.attributes,
                      width: img?.width * scaleRatio,
                      height: img?.height * scaleRatio,
                      viewBox: `0 0 ${img?.width * scaleRatio} ${img?.height * scaleRatio}`,
                    },
                    width: img?.width * scaleRatio,
                    height: img?.height * scaleRatio,
                    transform: {
                      x: (stateRatio?.ratioWidth - img?.width * scaleRatio) / 2,
                      y: (stateRatio?.ratioHeight - img?.height * scaleRatio) / 2,
                    },
                    rotate: {
                      a: 0,
                      x: 0,
                      y: 0,
                      baseScale: 1,
                    },
                  });
                }
                setDeletePath([]);
              };
              img.src = image?.src;
            }
          });
        },
      });
    }
  }, [image?.src, resetFromStep1]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            imageSize={imageSize}
            setImageSize={setImageSize}
            image={image}
            setImage={setImage}
            setImageBackUp={setImageBackUp}
            setStep={setStep}
            imageRef={imageRef}
            setIsRemoveBg={setIsRemoveBg}
            svgContent={svgContent}
            setNewImage={setNewImage}
            newImage={newImage}
            handleSetFeature={handleSetFeature}
          />
        );
      case 2:
        return (
          <Step2
            imageSize={imageSize}
            setImageSize={setImageSize}
            setNewImage={setNewImage}
            newImage={newImage}
            setImage={setImage}
            imageBackUp={imageBackUp}
            setStep={setStep}
            svgContent={svgContent}
            deletePath={deletePath}
            setDeletePath={setDeletePath}
            setSvgContent={setSvgContent}
            isRemoveBg={isRemoveBg}
            setResetFromStep1={setResetFromStep1}
            editMode={editMode}
            handleSetFeature={handleSetFeature}
          />
        );
      case 3:
        return (
          <Step3
            setStep={setStep}
            svgContent={svgContent}
            handleSetFeature={handleSetFeature}
            newImage={newImage}
            setNewImage={setNewImage}
            imageSize={imageSize}
            isRemoveBg={isRemoveBg}
            editMode={editMode}
            defaultStep={defaultStep}
            baseSvgContent={baseSvgContent}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={styles.wrapRenderStep}>
      <Button
        className="button-form !w-auto mb-3"
        onClick={() => {
          handleSetFeature();
        }}
      >
        キャンセル
      </Button>

      {renderStep()}
    </div>
  );
}
